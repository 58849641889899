@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.special_slider__background {
  padding-bottom: 55px;
  background-image: url(images/background_slaider.png);
  background-size: cover; }

.special_slider__item-title {
  text-align: center;
  color: #370A62;
  margin: 34px 0 34px 0;
  width: 100%; }

.special_slider__block {
  border-radius: 5px;
  display: flex;
  width: 1170px; }
  @media screen and (max-width: 1199px) {
    .special_slider__block {
      width: 768px; } }
  @media screen and (max-width: 767px) {
    .special_slider__block {
      width: 576px; } }
  .special_slider__block-img {
    width: 50%;
    display: block;
    -o-object-fit: cover;
       object-fit: cover; }
    @media screen and (max-width: 767px) {
      .special_slider__block-img {
        display: none !important; } }
  .special_slider__block-title {
    background: linear-gradient(290.89deg, #300E63 30.7%, #4F0645 65.81%);
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 35px 0;
    flex-direction: column; }
    @media screen and (max-width: 576px) {
      .special_slider__block-title > a, .special_slider__block-title > h3 {
        max-width: 250px; } }
    @media screen and (max-width: 767px) {
      .special_slider__block-title {
        width: 100%;
        min-height: 300px; } }
