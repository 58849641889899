@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.article__background {
  position: relative; }
  .article__background::before {
    content: '';
    background-image: url(images/background_line_article-left.png);
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    left: 0; }
    @media screen and (max-width: 1199px) {
      .article__background::before {
        display: none; } }
  .article__background::after {
    content: '';
    background: url(images/articles_list-astrologo.png) no-repeat 100% 100%;
    width: 583px;
    height: 587px;
    position: absolute;
    z-index: 10;
    top: -200px;
    right: 0; }
    @media screen and (max-width: 1199px) {
      .article__background::after {
        display: none; } }

.article {
  position: relative;
  z-index: 999; }
  .article__title {
    margin-top: 55px;
    position: relative;
    padding: 0;
    z-index: 20;
    margin-bottom: 20px; }
    .article__title-img {
      width: 100%; }
      @media screen and (max-width: 576px) {
        .article__title-img {
          display: none; } }
    .article__title-caption {
      position: absolute;
      top: 50%;
      text-align: center;
      z-index: 100;
      width: 100%;
      margin: 0; }
      @media screen and (max-width: 576px) {
        .article__title-caption {
          color: #370A62;
          position: unset; } }
  .article__tags {
    color: #370A62;
    font-weight: 700;
    font-size: 0.9em;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0 30px 0; }
    @media screen and (max-width: 576px) {
      .article__tags {
        flex-direction: column;
        align-items: baseline; } }
    .article__tags-item {
      padding: 3px 10px;
      border: 1px solid #6D0A5E;
      border-radius: 50px;
      margin-right: 10px; }
      @media screen and (max-width: 576px) {
        .article__tags-item {
          margin-bottom: 10px; } }
  .article__date {
    color: #575757;
    font-size: 0.8em;
    padding-top: 5px; }
