@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.entity__background {
  position: relative;
  min-height: 1050px;
  z-index: 500; }

.entity__background.taro {
  background: linear-gradient(104.16deg, rgba(254, 211, 99, 0.6039) 4.4%, rgba(255, 182, 74, 0.99) 43.96%, rgba(253, 239, 169, 0.99) 98.94%); }
  .entity__background.taro::before {
    content: '';
    background: url(images/left_circle_taro.png) no-repeat 100% 100%;
    width: 800px;
    height: 750px;
    position: absolute;
    z-index: 50;
    top: 50px;
    left: -110px; }
    @media screen and (max-width: 1800px) {
      .entity__background.taro::before {
        content: '';
        background: url(images/left_circle_taro1800.png) no-repeat 100% 100%;
        width: 529px;
        height: 514px;
        position: absolute;
        z-index: 50;
        top: -50px;
        left: 0; } }
    @media screen and (max-width: 1600px) {
      .entity__background.taro::before {
        content: '';
        background: url(images/left_circle_taro1200.png) no-repeat 100% 100%;
        width: 420px;
        height: 408px;
        position: absolute;
        z-index: 50;
        top: 0;
        left: -50px; } }
    @media screen and (max-width: 1199px) {
      .entity__background.taro::before {
        opacity: 0.3; } }
  .entity__background.taro::after {
    content: '';
    background: url(images/right_circle_taro.png) no-repeat 100% 100%;
    width: 838px;
    height: 805px;
    position: absolute;
    z-index: 50;
    bottom: 80px;
    right: 0; }
    @media screen and (max-width: 1800px) {
      .entity__background.taro::after {
        content: '';
        background: url(images/right_circle_taro1800.png) no-repeat 100% 100%;
        width: 500px;
        height: 480px;
        position: absolute;
        z-index: 50;
        bottom: 40px;
        right: 0; } }
    @media screen and (max-width: 1600px) {
      .entity__background.taro::after {
        content: '';
        background: url(images/right_circle_taro1200.png) no-repeat 100% 100%;
        width: 420px;
        height: 403px;
        position: absolute;
        z-index: 50;
        bottom: 40px;
        right: 0; } }
    @media screen and (max-width: 1199px) {
      .entity__background.taro::after {
        opacity: 0.3;
        bottom: -50px; } }
  .entity__background.taro .entity_list__title {
    border-bottom: 1px solid #6D0A5E;
    color: #370A62; }
  .entity__background.taro .entity_item:hover .entity_item-img {
    box-shadow: 0 0 20px 5px rgba(130, 25, 82, 0.7); }
  .entity__background.taro .entity_item-link::after {
    background: url(images/icon_taro.svg) no-repeat 100% 100%; }
  .entity__background.taro .entity_item-title {
    color: #370A62; }

.entity__background.astro {
  background: linear-gradient(101.91deg, rgba(109, 10, 94, 0.099) -8.02%, rgba(109, 10, 94, 0.6633) 13.22%, rgba(48, 14, 99, 0.99) 79.43%, rgba(84, 39, 152, 0.6633) 99.32%); }
  .entity__background.astro::before {
    content: '';
    background: url(images/left_astro_icon.png) no-repeat 100% 100%;
    width: 493px;
    height: 553px;
    position: absolute;
    z-index: 50;
    top: -50px;
    left: -110px; }
    @media screen and (max-width: 1800px) {
      .entity__background.astro::before {
        content: '';
        background: url(images/left_astro_icon1800.png) no-repeat 100% 100%;
        width: 393px;
        height: 441px;
        position: absolute;
        z-index: 50;
        top: -50px;
        left: 0; } }
    @media screen and (max-width: 1600px) {
      .entity__background.astro::before {
        content: '';
        background: url(images/left_astro_icon1200.png) no-repeat 100% 100%;
        width: 293px;
        height: 329px;
        position: absolute;
        z-index: 50;
        top: -30px;
        left: 0; } }
    @media screen and (max-width: 1199px) {
      .entity__background.astro::before {
        opacity: 0.3; } }
  .entity__background.astro::after {
    content: '';
    background: url(images/right_astro_icon.png) no-repeat 100% 100%;
    width: 540px;
    height: 496px;
    position: absolute;
    z-index: 50;
    bottom: 80px;
    right: 0; }
    @media screen and (max-width: 1800px) {
      .entity__background.astro::after {
        content: '';
        background: url(images/right_astro_icon1800.png) no-repeat 100% 100%;
        width: 360px;
        height: 404px;
        position: absolute;
        z-index: 50;
        bottom: 40px;
        right: 0; } }
    @media screen and (max-width: 1600px) {
      .entity__background.astro::after {
        content: '';
        background: url(images/right_astro_icon1200.png) no-repeat 100% 100%;
        width: 240px;
        height: 254px;
        position: absolute;
        z-index: 50;
        bottom: 40px;
        right: 0; } }
    @media screen and (max-width: 1199px) {
      .entity__background.astro::after {
        opacity: 0.3; } }
  .entity__background.astro .entity_list__title {
    border-bottom: 1px solid #FFAB24;
    color: #fff; }
  .entity__background.astro .entity_item:hover .entity_item-img {
    box-shadow: 0 0 10px 2px rgba(249, 203, 130, 0.4); }
  .entity__background.astro .entity_item-link::after {
    background: url(images/icon_astro.svg) no-repeat 100% 100%; }
  .entity__background.astro .entity_item-title {
    color: #fff; }

.entity__background.practices {
  background: linear-gradient(101.91deg, rgba(255, 183, 73, 0.99) -8.02%, rgba(48, 14, 99, 0.99) 76.4%, rgba(84, 39, 152, 0.8712) 95.96%); }
  .entity__background.practices::before {
    content: '';
    background: url(images/left_practic_icon.png) no-repeat 100% 100%;
    width: 430px;
    height: 523px;
    position: absolute;
    z-index: 50;
    top: -100px;
    left: -50px; }
    @media screen and (max-width: 1800px) {
      .entity__background.practices::before {
        content: '';
        background: url(images/left_practic_icon1800.png) no-repeat 100% 100%;
        width: 236px;
        height: 400px;
        position: absolute;
        z-index: 50;
        top: -50px;
        left: 0; } }
    @media screen and (max-width: 1600px) {
      .entity__background.practices::before {
        content: '';
        background: url(images/left_practic_icon1200.png) no-repeat 100% 100%;
        width: 207px;
        height: 350px;
        position: absolute;
        z-index: 50;
        top: -30px;
        left: 0; } }
    @media screen and (max-width: 1199px) {
      .entity__background.practices::before {
        opacity: 0.3;
        top: 0; } }
  .entity__background.practices::after {
    content: '';
    background: url(images/right_practic_icon.png) no-repeat 100% 100%;
    width: 421px;
    height: 447px;
    position: absolute;
    z-index: 50;
    bottom: 80px;
    right: 80px; }
    @media screen and (max-width: 1800px) {
      .entity__background.practices::after {
        content: '';
        background: url(images/right_practic_icon1800.png) no-repeat 100% 100%;
        width: 285px;
        height: 395px;
        position: absolute;
        z-index: 50;
        bottom: 40px;
        right: 0; } }
    @media screen and (max-width: 1600px) {
      .entity__background.practices::after {
        content: '';
        background: url(images/right_practic_icon1200.png) no-repeat 100% 100%;
        width: 210px;
        height: 291px;
        position: absolute;
        z-index: 50;
        bottom: 10px;
        right: 0; } }
    @media screen and (max-width: 1199px) {
      .entity__background.practices::after {
        opacity: 0.3; } }
  .entity__background.practices .entity_list__title {
    border-bottom: 1px solid #FFAB24;
    color: #fff; }
  .entity__background.practices .entity_item:hover .entity_item-img {
    box-shadow: 0 0 10px 2px rgba(249, 203, 130, 0.4); }
  .entity__background.practices .entity_item-link::after {
    background: url(images/icon_practic.svg) no-repeat 100% 100%; }
  .entity__background.practices .entity_item-title {
    color: #fff; }

.entity_list {
  z-index: 500;
  position: relative; }
  @media screen and (max-width: 340px) {
    .entity_list {
      overflow-x: hidden; } }
  .entity_list__title {
    text-align: center;
    padding-bottom: 15px;
    width: 25%;
    margin: 45px auto; }
    @media screen and (max-width: 1199px) {
      .entity_list__title {
        width: 100%; } }

.entity_item {
  position: relative; }
  .entity_item-link {
    position: relative;
    width: 275px;
    display: block;
    height: 100%; }
    .entity_item-link::after {
      content: '';
      width: 42px;
      height: 45px;
      position: absolute;
      top: 5px;
      right: 10px; }
    @media screen and (max-width: 760px) {
      .entity_item-link {
        width: 300px;
        margin: 0 auto; } }
  .entity_item-img {
    width: 275px;
    height: 205px; }
    .entity_item-img:hover {
      transition: 0.5s ease;
      box-shadow: 0 0 10px 2px rgba(249, 203, 130, 0.4); }
    @media screen and (max-width: 760px) {
      .entity_item-img {
        width: 100%;
        height: auto; } }
  .entity_item-title {
    text-align: center;
    margin: 20px 0;
    text-shadow: 2px 4px 20px rgba(109, 10, 94, 0.6); }
