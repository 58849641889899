.slick-slider {
  position: relative; }
  @media screen and (max-width: 760px) {
    .slick-slider {
      min-height: 440px; } }

.slick-track {
  display: flex; }

.slick-initialized .slick-slide {
  display: flex;
  height: auto; }

.slick-slide {
  display: flex;
  height: auto; }

.slick-slide:focus, .slick-slide:active {
  outline: 0; }

.slick-list::before, .slick-list::after {
  content: "";
  position: absolute;
  display: none;
  width: 150px;
  top: 50px;
  bottom: 50px;
  background-color: transparent;
  z-index: 998; }
  @media (min-width: 1024px) {
    .slick-list::before, .slick-list::after {
      display: block; } }

.slick-list::before {
  left: 0; }

.slick-list::after {
  right: 0; }

.slick-btn {
  position: absolute;
  top: 50%;
  margin-top: -31px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: red;
  z-index: 999; }
  .slick-btn:hover {
    background-color: red; }
  .slick-btn:focus, .slick-btn:active {
    outline: none;
    background-color: red;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  .slick-btn::before {
    content: "";
    position: absolute;
    top: 50%;
    display: block;
    margin-top: -16px;
    width: 32px;
    height: 32px;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxMCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgMUwxIDlMOSAxNyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==) center center no-repeat; }

.slick-btn.prev {
  left: 60px; }
  .slick-btn.prev::before {
    left: 13px; }

.slick-btn.next {
  right: 60px; }
  .slick-btn.next::before {
    right: 13px;
    transform: rotate(180deg); }

.slick-dots {
  position: absolute;
  bottom: -20px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }

.slick-dots li button {
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }

.slick-dots li button:before {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  content: "";
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(52, 58, 66, 0.3);
  box-sizing: border-box; }

.slick-dots li.slick-active button:before {
  background: #343a42; }

.slick-dots--white li button:before {
  border: 1px solid rgba(255, 255, 255, 0.3); }

.slick-dots--white li.slick-active button:before {
  background: #fff; }

.slick--grey .slick-list::before {
  background-image: linear-gradient(to right, #fbfbfb 0%, rgba(255, 255, 255, 0) 100%); }

.slick--grey .slick-list::after {
  background-image: linear-gradient(to left, #fbfbfb 0%, rgba(255, 255, 255, 0) 100%); }

.slick--white .slick-list::before {
  background-image: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%); }

.slick--white .slick-list::after {
  background-image: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%); }

.slick--catalog, .slick--product, .slick--article {
  padding-bottom: 20px; }

.slick--catalog .slick-list {
  margin: 0 -125px; }
  @media (min-width: 700px) {
    .slick--catalog .slick-list {
      margin: 0; } }
  @media (min-width: 1024px) {
    .slick--catalog .slick-list {
      margin: 0 -10px; } }

.slick--catalog .slick-list::before {
  left: 10px; }

.slick--catalog .slick-list::after {
  right: 10px; }

.slick--catalog .slick-dots {
  bottom: 0; }

.slick--advantages .slick-dots {
  bottom: -40px; }

@media (min-width: 700px) {
  .slick--product .slick-list {
    margin: 0 -323px; } }

@media (min-width: 1024px) {
  .slick--product .slick-list {
    margin: 0; } }

@media (min-width: 700px) {
  .slick--product .slick-list::before {
    left: 323px; } }

@media (min-width: 1024px) {
  .slick--product .slick-list::before {
    left: 0; } }

@media (min-width: 700px) {
  .slick--product .slick-list::after {
    right: 323px; } }

@media (min-width: 1024px) {
  .slick--product .slick-list::after {
    right: 0; } }

@media (min-width: 1024px) {
  .slick--article .slick-list {
    margin: 0 -250px; } }

@media (min-width: 1024px) {
  .slick--article .slick-list::before {
    left: 250px; } }

@media (min-width: 1024px) {
  .slick--article .slick-list::after {
    right: 250px; } }

.slick--product-info .slick-slide {
  height: 160px;
  width: 100%; }
  @media (min-width: 768px) {
    .slick--product-info .slick-slide {
      height: 240px; } }

.slick--product-info .slick-dots {
  left: 0;
  bottom: -40px; }
  @media (min-width: 768px) {
    .slick--product-info .slick-dots {
      bottom: 50px; } }
  @media (min-width: 1024px) {
    .slick--product-info .slick-dots {
      bottom: 44px; } }

@media (min-width: 1024px) {
  .slick--not-gradient .slick-list::before,
  .slick--not-gradient .slick-list::after {
    display: none; } }

.slick--spinner {
  height: 200px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 1s ease; }

.slick--spinner.slick-initialized {
  height: auto;
  visibility: visible;
  opacity: 1; }

.slick--spinner.slick-initialized + .spinner {
  display: none; }
