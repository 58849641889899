.input {
  position: relative;
  width: 100%;
  height: 64px;
  padding: 22px 20px 22px 20px;
  background-color: #fff;
  border: 1px solid #ededed;
  box-shadow: 0 5px 50px rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none; }
  .input:-webkit-autofill, .input:-webkit-autofill:hover, .input:-webkit-autofill:focus, .input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 50px white inset !important; }
  .input::-moz-placeholder {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -.02em;
    color: rgba(45, 51, 58, 0.4); }
  .input:-ms-input-placeholder {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -.02em;
    color: rgba(45, 51, 58, 0.4); }
  .input::-ms-input-placeholder {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -.02em;
    color: rgba(45, 51, 58, 0.4); }
  .input::placeholder {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -.02em;
    color: rgba(45, 51, 58, 0.4); }
  .input:focus {
    border: 1px solid #4b688e;
    outline: 0; }
  .input::-ms-clear {
    display: none; }
  .input::before {
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -10px;
    display: block;
    background-color: #fff;
    width: 20px;
    height: 20px;
    content: "";
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjY2NjcgMTcuNVYxNS44MzMzQzE2LjY2NjcgMTQuOTQ5MyAxNi4zMTU1IDE0LjEwMTQgMTUuNjkwNCAxMy40NzYzQzE1LjA2NTMgMTIuODUxMiAxNC4yMTc0IDEyLjUgMTMuMzMzNCAxMi41SDYuNjY2NzFDNS43ODI2NSAxMi41IDQuOTM0ODEgMTIuODUxMiA0LjMwOTY4IDEzLjQ3NjNDMy42ODQ1NiAxNC4xMDE0IDMuMzMzMzcgMTQuOTQ5MyAzLjMzMzM3IDE1LjgzMzNWMTcuNSIgc3Ryb2tlPSIjNEI2ODhFIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTkuOTk5OTYgOS4xNjY2N0MxMS44NDA5IDkuMTY2NjcgMTMuMzMzMyA3LjY3NDI4IDEzLjMzMzMgNS44MzMzM0MxMy4zMzMzIDMuOTkyMzggMTEuODQwOSAyLjUgOS45OTk5NiAyLjVDOC4xNTkwMSAyLjUgNi42NjY2MyAzLjk5MjM4IDYuNjY2NjMgNS44MzMzM0M2LjY2NjYzIDcuNjc0MjggOC4xNTkwMSA5LjE2NjY3IDkuOTk5OTYgOS4xNjY2N1oiIHN0cm9rZT0iIzRCNjg4RSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=) center center no-repeat; }
  .input--icon {
    padding-left: 60px; }
  .input--user + .input__icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjY2NjcgMTcuNVYxNS44MzMzQzE2LjY2NjcgMTQuOTQ5MyAxNi4zMTU1IDE0LjEwMTQgMTUuNjkwNCAxMy40NzYzQzE1LjA2NTMgMTIuODUxMiAxNC4yMTc0IDEyLjUgMTMuMzMzNCAxMi41SDYuNjY2NzFDNS43ODI2NSAxMi41IDQuOTM0ODEgMTIuODUxMiA0LjMwOTY4IDEzLjQ3NjNDMy42ODQ1NiAxNC4xMDE0IDMuMzMzMzcgMTQuOTQ5MyAzLjMzMzM3IDE1LjgzMzNWMTcuNSIgc3Ryb2tlPSIjNEI2ODhFIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTkuOTk5OTYgOS4xNjY2N0MxMS44NDA5IDkuMTY2NjcgMTMuMzMzMyA3LjY3NDI4IDEzLjMzMzMgNS44MzMzM0MxMy4zMzMzIDMuOTkyMzggMTEuODQwOSAyLjUgOS45OTk5NiAyLjVDOC4xNTkwMSAyLjUgNi42NjY2MyAzLjk5MjM4IDYuNjY2NjMgNS44MzMzM0M2LjY2NjYzIDcuNjc0MjggOC4xNTkwMSA5LjE2NjY3IDkuOTk5OTYgOS4xNjY2N1oiIHN0cm9rZT0iIzRCNjg4RSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=); }
  .input--phone + .input__icon {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAhFBMVEUAAABAcH9QcI9Ka4xKa5RIaIdIaI9QaI9KZYpKZY9QZYpQZY9Qao9MaItMaI9KZ45Kao5MaYxLaY5KaI1JZ45MZ45LaY1KaI1MaI1JaY1JaY9LaY9MaI5KaI1LaI9KZ41KZ49MZ41MaY9LaI5LaI9KZ45LaY5KaI5KaI9LaI5LZ45MZ44s5F2EAAAALHRSTlMAEBAfHyAgIDAwMDAwQEBPT1BfYG9vcH+Aj4+PkJ+fr6+vr7+/z8/f39/v72q5WUMAAACiSURBVBjTbZHhEoIgEITPiLIMKwmzTDSxTuP93y9wRhTG+3HMfbOzuzMArM6m7Pu+i30oa0JI/vNhe7Ybt77yNm83QtrNaw/uRjuufFPFABIM4oURtc+wqGZAh0AJuZHyYR9QVQDcDU2aLjtMkGIGcNUlXnj1dUZHbSitxpOpWVu43uhsqZqSFtDko0zte/osO0QcB5mlryroFou3ekTrn/EHBNcKYHHZuI0AAAAASUVORK5CYII=); }
  .input--mail + .input__icon {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMCAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuMzMzNjYgNC4yNTQ2NEgxNi42NjdDMTcuNTgzNyA0LjI1NDY0IDE4LjMzMzcgNC45OTI1OCAxOC4zMzM3IDUuODk0NVYxNS43MzM3QzE4LjMzMzcgMTYuNjM1NiAxNy41ODM3IDE3LjM3MzUgMTYuNjY3IDE3LjM3MzVIMy4zMzM2NkMyLjQxNjk5IDE3LjM3MzUgMS42NjY5OSAxNi42MzU2IDEuNjY2OTkgMTUuNzMzN1Y1Ljg5NDVDMS42NjY5OSA0Ljk5MjU4IDIuNDE2OTkgNC4yNTQ2NCAzLjMzMzY2IDQuMjU0NjRaIiBzdHJva2U9IiM0QjY4OEUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTguMzMzNyA1Ljg5NDUzTDEwLjAwMDMgMTEuNjM0TDEuNjY2OTkgNS44OTQ1MyIgc3Ryb2tlPSIjNEI2ODhFIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==); }
  .input._valid {
    border: 1px solid green; }
  .input._error {
    border: 1px solid #d54b48; }
  .input._error ~ span.error {
    display: block; }
  .input__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 60px;
    height: 64px;
    background-repeat: no-repeat;
    background-position: 22px 20px; }
  .input--radio {
    display: none;
    margin: 10px; }
  .input--radio + label {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #4b688e;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -.02em;
    color: #4b688e; }
    @media (min-width: 700px) {
      .input--radio + label {
        width: 240px; } }
  .input--radio:checked + label {
    background-image: none;
    background-color: #4b688e;
    color: #fff; }
  .input--h56 {
    height: 56px;
    padding: 17px 20px 16px 20px; }
