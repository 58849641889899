@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.feedback__background {
  height: 521px;
  background: url(images/background_feedback.png) top left no-repeat;
  position: relative; }
  @media screen and (max-width: 767px) {
    .feedback__background {
      height: 525px; } }
  @media screen and (max-width: 576px) {
    .feedback__background {
      background-position-x: right;
      margin-bottom: 30px; } }

.feedback__background::before {
  content: '';
  background: url(images/feedback_img_left.png) no-repeat;
  display: block;
  position: absolute;
  width: 734px;
  height: 340px;
  left: -250px;
  bottom: 120px; }
  @media screen and (min-width: 1200px) and (max-width: 1366px) {
    .feedback__background::before {
      background-position: -300px; } }
  @media screen and (min-width: 767px) and (max-width: 1199px) {
    .feedback__background::before {
      background-position: -300px; } }
  @media screen and (max-width: 768px) {
    .feedback__background::before {
      display: none; } }

.feedback__background::after {
  content: '';
  background: url(images/feedback_img_right.png) no-repeat;
  display: block;
  position: absolute;
  width: 667px;
  height: 340px;
  right: 0;
  bottom: 120px; }
  @media screen and (min-width: 1200px) and (max-width: 1366px) {
    .feedback__background::after {
      width: 30%; } }
  @media screen and (max-width: 1199px) {
    .feedback__background::after {
      width: 40%; } }

.feedback {
  text-align: center; }
  .feedback__title {
    margin: 55px 0 50px 0; }
    @media screen and (max-width: 767px) {
      .feedback__title {
        margin: 35px 0 35px; } }
    @media screen and (max-width: 576px) {
      .feedback__title {
        margin: 30px 0 20px; } }
  .feedback__forma {
    border: 1px solid #FDEFA9;
    -webkit-filter: drop-shadow(0px 4px 20px rgba(253, 239, 169, 0.83));
            filter: drop-shadow(0px 4px 20px rgba(253, 239, 169, 0.83));
    border-radius: 3px;
    width: 82%;
    margin: 0 auto;
    padding: 41px 10px 41px; }
    @media screen and (max-width: 576px) {
      .feedback__forma {
        padding: 20px 10px 20px; } }
    .feedback__forma-offer {
      max-width: 75%;
      display: block;
      margin: 0 auto;
      font-size: 1.1em;
      line-height: 1.5; }
      @media screen and (max-width: 1199px) {
        .feedback__forma-offer {
          font-size: 1em; } }
      @media screen and (max-width: 767px) {
        .feedback__forma-offer {
          font-size: 0.9em; } }
    .feedback__forma-link {
      border: 1px solid #FDEFA9;
      -webkit-filter: drop-shadow(0px 4px 20px rgba(253, 239, 169, 0.83));
              filter: drop-shadow(0px 4px 20px rgba(253, 239, 169, 0.83));
      border-radius: 50px;
      padding: 19px 0;
      display: block;
      max-width: 230px;
      margin: 30px auto 0; }
      @media screen and (max-width: 767px) {
        .feedback__forma-link {
          margin: 15px auto 0;
          padding: 12px 0;
          max-width: 180px;
          font-size: 0.9em; } }
