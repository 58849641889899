@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
/**
 * За пределами блока typography удобно использовать классы заголовков.
 * Отступы для таких классов обнулены, т.к. обычно задаются в контексте интерфейса.
 */
.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
  color: inherit;
  line-height: 1.2;
  font-weight: 500; }

.typography h1 {
  font-size: 2.5rem; }

.typography h2 {
  font-size: 1.5rem; }

.typography h3 {
  font-size: 1.25rem; }

.typography h4 {
  font-size: 1.15rem; }

.typography h5 {
  font-size: 1.15rem; }

.typography h6 {
  font-size: 1.15rem; }

/**
 * Блок typography используется там, где необходимо отображать оформленный текст.
 * Обычно это содержимое страниц, заполненное в WYSIWYG редакторе.
 */
.typography.article__content p {
  text-indent: 1.5em; }

.typography {
  font-size: 1rem;
  color: #000000;
  line-height: 1.3rem; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6 {
    margin-bottom: .5rem; }
  .typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6, .typography a {
    color: #370A62; }
  @media screen and (max-width: 576px) {
    .typography h1 {
      font-size: 1.5rem; } }
  .typography p {
    margin-bottom: 1rem; }
  .typography hr {
    height: 0;
    margin: 1rem 0;
    border-left: 0;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #ffffff; }
  .typography i, .typography em {
    font-style: italic; }
  .typography b, .typography strong {
    font-weight: bolder; }
  .typography blockquote {
    font-size: 1.15rem;
    padding: .25rem 0 .25rem 1rem;
    margin-bottom: 1rem;
    border-left: 2px solid #6d0a5e;
    background-color: rgba(48, 14, 85, 0.1); }
    @media screen and (max-width: 576px) {
      .typography blockquote {
        font-size: 0.8rem;
        line-height: 1rem; } }
    @media screen and (max-width: 767px) {
      .typography blockquote {
        font-size: 0.8rem;
        line-height: 1rem; } }
  .typography ul, .typography ol {
    padding-left: 40px;
    margin-bottom: 1rem; }
  .typography ul {
    list-style-type: disc; }
    .typography ul ul {
      list-style-type: circle; }
  .typography ol {
    list-style-type: decimal; }
  .typography li {
    display: list-item; }
  .typography mark {
    background-color: #fcf8e3; }
  .typography small {
    font-size: 80%; }
  .typography img,
  .typography picture {
    max-width: 100%;
    height: auto; }
    @media screen and (max-width: 1199px) {
      .typography img,
      .typography picture {
        max-width: 100%;
        height: auto !important; } }
  .typography table {
    max-width: 100%;
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #dee2e6;
    background-color: transparent; }
    .typography table th, .typography table td {
      padding: .75rem;
      text-align: inherit;
      border: 1px solid #dee2e6; }
    .typography table thead th {
      font-weight: bold;
      border-bottom: 2px solid #dee2e6; }
