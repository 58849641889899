@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.header__background {
  background-image: url(/assets/site/images/background_heder.png);
  background-size: cover; }
  @media (max-width: 1199px) {
    .header__background {
      background-position: 70%; } }

.header__top {
  margin-top: 25px;
  justify-content: space-around;
  position: relative; }
  .header__top-order {
    padding: 15px 36px;
    font-size: 0.7em;
    background: linear-gradient(102.63deg, #370A62 28.35%, #6D0A5E 80.78%);
    box-shadow: 0px 2px 20px rgba(253, 239, 169, 0.3);
    border-radius: 50px;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    text-transform: uppercase; }
    .header__top-order:hover {
      box-shadow: 2px 2px 25px rgba(204, 135, 146, 0.7);
      transition: all .85s ease-in; }
  .header__top-logo {
    margin-bottom: 100px; }
    @media (max-width: 1199px) {
      .header__top-logo {
        margin-bottom: 80px; } }
    @media (max-width: 768px) {
      .header__top-logo {
        margin-bottom: 15px;
        margin-top: -5px; } }
  .header__top-phone {
    position: relative;
    font-weight: 600;
    color: #FDDBA9;
    z-index: 600; }
    .header__top-phone > a {
      color: #FDDBA9; }
    .header__top-phone::before {
      content: '';
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M24.0062 4.59291C21.3502 1.90888 17.8186 0.430786 14.0625 0.430786C10.3064 0.430786 6.77479 1.90888 4.11884 4.59291C1.46273 7.27676 0 10.8455 0 14.6411C0 18.4366 1.46273 22.0053 4.11884 24.6892C6.77479 27.3732 10.3064 28.8513 14.0625 28.8513C14.1758 28.8513 14.2908 28.8499 14.4044 28.8472C14.8597 28.8359 15.2195 28.4543 15.2083 27.9946C15.1974 27.5349 14.8202 27.1718 14.3648 27.1824C14.2642 27.1847 14.1624 27.1861 14.0625 27.1861C7.21699 27.1861 1.64795 21.5585 1.64795 14.6411C1.64795 7.72361 7.21699 2.09605 14.0625 2.09605C20.908 2.09605 26.4771 7.72361 26.4771 14.6411C26.4771 17.1664 25.7344 19.6049 24.3296 21.6935C24.0556 22.1009 23.6879 22.3335 23.2059 22.405C22.7235 22.4765 22.3053 22.3601 21.9272 22.0496L21.2743 21.5134C21.3523 21.4499 21.4292 21.3847 21.5047 21.317C21.989 20.8825 22.23 20.2837 22.1831 19.6308C22.1361 18.9779 21.8123 18.4205 21.2709 18.0609L18.6611 16.3278C17.9242 15.8383 16.9674 15.8812 16.28 16.4338C15.8934 16.7445 15.3596 16.714 15.0102 16.3611L12.3603 13.6832C12.0111 13.3304 11.9809 12.7909 12.2884 12.4002C12.8353 11.7057 12.8777 10.7388 12.3933 9.9941L10.6782 7.3569C10.3223 6.80979 9.77079 6.48229 9.12466 6.43511C8.47836 6.38758 7.88595 6.6313 7.45594 7.12064C5.33489 9.53424 5.44355 13.2134 7.70313 15.4966L13.2159 21.0672C14.3914 22.2553 15.9408 22.855 17.4937 22.8548C18.262 22.8548 19.0306 22.7065 19.7555 22.4114L20.8874 23.3417C21.6226 23.9457 22.5072 24.1917 23.4448 24.0529C24.3826 23.9138 25.1597 23.4219 25.6926 22.6298C27.2839 20.2639 28.125 17.5015 28.125 14.6411C28.125 10.8455 26.6623 7.27676 24.0062 4.59291Z' fill='%23FDDBA9' fill-opacity='0.77'/%3E %3C/svg%3E") no-repeat 50%/contain;
      width: 28px;
      height: 28px;
      position: absolute;
      left: -23px;
      top: -4px;
      z-index: 50; }
      @media (max-width: 768px) {
        .header__top-phone::before {
          left: 10px; } }
    @media (max-width: 768px) {
      .header__top-phone {
        margin-top: 25px;
        padding-left: 45px; } }

.header__center {
  position: relative; }
  .header__center-logo2 {
    width: 270px;
    height: 123.86px;
    left: 371px;
    top: 180px;
    display: block; }
    @media (max-width: 768px) {
      .header__center-logo2 {
        margin: 20px auto 0;
        width: 200px; } }
  .header__center-caption {
    font-size: 1.6em;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .header__center-caption {
        text-align: center;
        font-size: 1.2em; } }
  .header__center-paragraph {
    line-height: 25px; }
    @media (max-width: 768px) {
      .header__center-paragraph {
        text-align: center;
        margin-bottom: 30px; } }
  .header__center-image {
    position: absolute;
    left: 615px;
    top: -243px;
    z-index: 10;
    max-width: 48%; }
    @media screen and (max-width: 992px) {
      .header__center-image {
        display: none; } }
    @media (max-width: 1199px) and (min-width: 992px) {
      .header__center-image {
        width: 40%;
        left: 500px;
        top: -130px; } }

.header__menu {
  margin-top: 125px;
  margin-bottom: 35px; }
  @media (max-width: 992px) {
    .header__menu {
      display: none; } }
