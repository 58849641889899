@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.advantages__background {
  padding-bottom: 180px;
  background: #300E63 url(images/background_advantages_element.png) top left no-repeat;
  z-index: 10;
  position: relative; }
  @media (max-width: 1024px) {
    .advantages__background {
      padding-bottom: 20px; } }

.advantages__title {
  text-align: center;
  margin: 55px 0 60px 0; }

.advantages__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media (max-width: 1024px) {
    .advantages__list {
      justify-content: space-around; } }
  .advantages__list::after {
    content: '';
    background-image: url(images/background_advantages_element2.png);
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    width: 521px;
    height: 670px;
    z-index: 300;
    right: 0;
    top: 0; }
    @media (max-width: 1024px) {
      .advantages__list::after {
        background-size: contain;
        width: 220px;
        height: 283px; } }
    @media screen and (max-width: 576px) {
      .advantages__list::after {
        width: 200px; } }
  .advantages__list-item {
    width: 30%;
    line-height: 20px;
    font-size: 1em; }
    @media screen and (max-width: 992px) {
      .advantages__list-item {
        width: 45%;
        margin-bottom: 20px; } }
    @media screen and (max-width: 768px) {
      .advantages__list-item {
        width: 90%; } }
    .advantages__list-item:nth-child(even) {
      border: 1px solid #FDEFA9;
      box-sizing: border-box;
      box-shadow: 0px -5px 101px -55px rgba(253, 239, 169, 0.66);
      border-radius: 3px;
      padding: 0 12px; }
