@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.breadcrumbs {
  background-color: #e8dcff;
  padding: 10px 0;
  z-index: 600; }

.breadcrumbs__list {
  display: flex;
  justify-content: end;
  font-size: 0.8rem;
  line-height: 0.8rem;
  letter-spacing: .04em;
  list-style: none; }
  .breadcrumbs__list-item {
    margin-right: 10px;
    padding-right: 10px;
    position: relative; }
    .breadcrumbs__list-item:not(:last-child):after {
      content: "";
      height: 12px;
      width: 1px;
      background-color: #b491d6;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
  .breadcrumbs__list-link {
    color: #370A62; }
    .breadcrumbs__list-link:hover {
      color: #860672;
      transition: all .55s ease; }
  .breadcrumbs__list-text {
    color: #8d69af; }
