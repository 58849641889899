@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.pagination {
  position: relative;
  z-index: 200;
  margin: 50px auto; }
  .pagination__list {
    display: flex;
    justify-content: center;
    list-style: none;
    position: relative; }
  .pagination__link {
    color: #370A62;
    padding: 10px 18px; }
    .pagination__link:hover {
      color: #860672;
      transition: all .55s ease; }
    .pagination__link-active {
      border: 1px dashed #370A62;
      border-radius: 50%; }
