@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.mobile-menu {
  display: none; }
  @media screen and (max-width: 992px) {
    .mobile-menu {
      display: block;
      width: 40%;
      min-width: 360px;
      background: linear-gradient(102.63deg, #370a62e0 28.35%, #6d0a5ee3 85.78%);
      position: fixed;
      top: 0;
      height: 100%;
      z-index: 990;
      right: 0;
      transition: all 5s; } }
  .mobile-menu__list {
    display: flex;
    list-style: none;
    font-size: 1em;
    flex-direction: column;
    align-items: center;
    padding: 0; }
    .mobile-menu__list-item {
      padding-bottom: 10px;
      letter-spacing: 1px;
      font-size: 1.2em; }
    .mobile-menu__list-link:hover {
      color: #FDEFA9;
      transition: color .7s ease-in; }
  .mobile-menu__logo {
    width: 50%;
    margin: 15px auto;
    display: block; }
