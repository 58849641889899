@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.school__background {
  position: relative; }
  .school__background::before {
    content: '';
    background: url(images/articles_list-astrologo.png) no-repeat 100% 100%;
    width: 583px;
    height: 587px;
    position: absolute;
    z-index: 50;
    top: -200px;
    right: 0; }
    @media (max-width: 1199px) {
      .school__background::before {
        display: none; } }

.school {
  margin-bottom: 45px;
  position: relative;
  z-index: 100; }
  .school__text {
    margin-top: 45px; }
  .school__title {
    border-bottom: 1px solid #6D0A5E;
    padding-bottom: 15px;
    color: #370A62;
    width: 60%;
    margin: 0; }
    @media screen and (max-width: 576px) {
      .school__title {
        width: 100%; } }
  .school__link {
    color: #370A62;
    margin-top: 5px;
    display: block; }
  .school__logo {
    margin-top: 45px; }
    .school__logo-img {
      webkit-box-shadow: 0px 4px 30px rgba(55, 10, 98, 0.5);
      box-shadow: 0px 4px 30px rgba(55, 10, 98, 0.5); }
      @media (max-width: 1199px) and (min-width: 767px) {
        .school__logo-img {
          width: 100%; } }
