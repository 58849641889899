@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.page__background {
  background-color: #F6EEFD;
  min-height: 400px; }

.page {
  padding-bottom: 30px; }
  .page__title {
    text-align: center;
    border-bottom: 1px solid #6D0A5E;
    padding-bottom: 15px;
    color: #370A62;
    width: 25%;
    margin: 45px auto;
    color: #370A62; }
    @media screen and (max-width: 1199px) {
      .page__title {
        width: 100%; } }
