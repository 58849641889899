@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.footer__background {
  background-image: url(/assets/site/images/background_footer.png);
  background-size: cover; }

.footer {
  height: 300px;
  z-index: 999;
  position: relative; }
  .footer__info {
    margin-top: 20px; }
  .footer__menu {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0 0 0 20px; }
    .footer__menu-item {
      margin-bottom: 10px; }
    .footer__menu-link:hover {
      color: #FDEFA9;
      transition: color .7s ease-in; }

.footer__bottom {
  height: 60px;
  background: #300E63;
  font-size: 0.8em; }
  .footer__bottom-copyright {
    margin-top: 20px; }
    @media screen and (max-width: 576px) {
      .footer__bottom-copyright {
        margin-top: 15px; } }
