@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.reviews__background {
  height: 535px;
  position: relative; }

.reviews__background::before {
  content: '';
  background: url(images/reviews_left_taro.png) no-repeat;
  display: block;
  position: absolute;
  width: 444px;
  height: 526px;
  z-index: 10;
  left: 0;
  bottom: 90px; }
  @media screen and (max-width: 1199px) {
    .reviews__background::before {
      background-size: contain;
      height: 60%;
      top: -120px; } }
  @media screen and (max-width: 768px) {
    .reviews__background::before {
      display: none; } }

.reviews__background::after {
  content: '';
  background: url(images/reviews_right_taro.png) no-repeat;
  display: block;
  position: absolute;
  width: 617px;
  height: 526px;
  z-index: 10;
  right: 0;
  top: 200px; }
  @media screen and (max-width: 1199px) {
    .reviews__background::after {
      background-size: contain;
      width: 450px;
      height: 383px;
      top: 300px; } }
  @media screen and (max-width: 768px) {
    .reviews__background::after {
      display: none; } }

.reviews__title {
  color: #370A62;
  text-align: center;
  margin: 0 0 50px;
  position: relative; }
  .reviews__title::after {
    content: '';
    background: url(images/reviews_crystal.png) no-repeat;
    display: block;
    position: absolute;
    width: 269px;
    height: 255px;
    z-index: 300;
    top: -80px;
    right: 255px; }
    @media screen and (max-width: 1199px) {
      .reviews__title::after {
        right: 0; } }
    @media screen and (max-width: 767px) {
      .reviews__title::after {
        display: none; } }

.reviews__wrapper {
  background: rgba(48, 14, 99, 0.65);
  box-shadow: inset 0px 4px 100px rgba(55, 10, 98, 0.8);
  border-radius: 5px;
  z-index: 100; }
  .reviews__wrapper-height {
    height: 400px !important;
    width: 1170px; }
    @media screen and (max-width: 1199px) {
      .reviews__wrapper-height {
        width: 768px;
        height: 400px !important; } }
    @media screen and (max-width: 767px) {
      .reviews__wrapper-height {
        width: 576px; } }
  .reviews__wrapper-img {
    display: block;
    width: 200px;
    height: 200px;
    border: 3px solid #FDEFA9;
    box-sizing: border-box;
    border-radius: 50%;
    -webkit-filter: drop-shadow(0px 4px 50px rgba(253, 239, 169, 0.5));
            filter: drop-shadow(0px 4px 50px rgba(253, 239, 169, 0.5));
    overflow: hidden; }
    @media screen and (max-width: 760px) {
      .reviews__wrapper-img {
        margin-top: 20px;
        width: 150px;
        height: 150px; } }
    @media screen and (max-width: 768px) {
      .reviews__wrapper-img {
        display: none; } }
  .reviews__wrapper-block {
    display: flex;
    justify-content: space-around;
    align-items: center; }
    @media screen and (max-width: 992px) {
      .reviews__wrapper-block {
        justify-content: center; } }
    .reviews__wrapper-block-text {
      padding-right: 100px;
      line-height: 1.4em; }
      @media screen and (max-width: 1199px) {
        .reviews__wrapper-block-text {
          padding-right: 200px; } }
      @media screen and (max-width: 992px) {
        .reviews__wrapper-block-text {
          padding-right: 0; } }
    @media screen and (max-width: 992px) {
      .reviews__wrapper-block-info {
        padding: 0 20px;
        min-height: 430px; } }
    @media screen and (max-width: 576px) {
      .reviews__wrapper-block-info {
        max-width: 250px; } }
