@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.request-form__form {
  color: #370A62;
  z-index: 500; }

.request-form__message {
  display: none;
  text-align: center;
  font-size: 20px;
  line-height: 19px;
  padding-top: 100px;
  min-height: 200px;
  font-weight: 600; }
  .request-form__message.active {
    display: block; }

.request-form__fieldset {
  width: 70%;
  margin: 40px auto 40px;
  border: solid #bd71cacc;
  background-color: #e6e2e8cc;
  box-shadow: 0 0 50px 5px rgba(143, 96, 208, 0.3); }
  @media screen and (max-width: 1199px) {
    .request-form__fieldset {
      width: 90%; } }

.request-form__legend {
  display: inline-block;
  width: auto;
  padding: 0 0.5rem;
  margin-bottom: 1rem; }

.request-form__feedback-error {
  display: none; }

.request-form__group {
  margin: 0 8px 16px 8px;
  display: flex;
  flex-direction: column; }

.request-form__input, .request-form__select, .request-form__textarea, .request-form__price, .request-form__submit, .request-form__radio {
  height: auto;
  padding: 10px;
  border: 1px solid #ced4da;
  box-sizing: border-box;
  margin-top: 10px;
  outline: none; }

.request-form__select {
  width: 45%; }
  @media screen and (max-width: 1199px) {
    .request-form__select {
      width: 70%; } }
  @media screen and (max-width: 576px) {
    .request-form__select {
      width: 100%; } }

.request-form__radio {
  margin: 20px 10px 10px 5px; }

.request-form__textarea {
  width: 45%;
  resize: none; }
  @media screen and (max-width: 576px) {
    .request-form__textarea {
      width: 100%; } }
  .request-form__textarea-label {
    margin-top: 10px; }

.request-form__price {
  width: 20%;
  margin-right: 5px; }
  @media screen and (max-width: 576px) {
    .request-form__price {
      width: 45%; } }

.request-form__submit {
  color: #fff;
  background-color: #370A62;
  margin: 10px auto 0;
  font-size: 0.9em;
  border: 1px solid #370A62;
  border-radius: 5px;
  cursor: pointer; }

.request-form__confidentiality {
  display: flex;
  justify-content: end;
  font-size: 0.8em; }
  .request-form__confidentiality-label {
    margin-left: 5px; }
