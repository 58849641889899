@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.articles__background {
  margin-bottom: 40px;
  position: relative; }

.articles__background::before {
  content: '';
  background: url(images/articles_img_background.png) no-repeat;
  display: block;
  position: absolute;
  width: 740px;
  height: 1017px;
  z-index: 10;
  top: -210px; }
  @media screen and (max-width: 768px) {
    .articles__background::before {
      display: none; } }

.articles__background::after {
  content: '';
  background: url(images/articles__line_img.png) no-repeat;
  display: block;
  position: absolute;
  width: 873px;
  height: 1000px;
  z-index: 10;
  right: 0;
  bottom: 0; }

.articles {
  color: #370A62;
  position: relative;
  z-index: 300; }
  .articles__title {
    text-align: center;
    margin: 55px 0 60px 0; }
  .articles__wrapper {
    min-height: 480px;
    background: linear-gradient(71.82deg, rgba(181, 145, 145, 0.7) 39.62%, rgba(79, 6, 69, 0.8) 85%);
    box-shadow: inset 0px 4px 60px rgba(55, 10, 98, 0.8);
    border-radius: 3px;
    color: #fff;
    z-index: 600; }
    @media (max-width: 1024px) {
      .articles__wrapper {
        width: 100%;
        min-height: 435px; } }
  .articles__main {
    padding: 10px;
    position: relative; }
    @media (max-width: 1024px) {
      .articles__main {
        padding: 20px 10px; } }
    .articles__main > img {
      width: 100%; }
    .articles__main-item {
      position: absolute;
      bottom: 25px;
      left: 20px;
      background: rgba(48, 14, 99, 0.6);
      border-radius: 3px;
      padding: 10px;
      width: 91%; }
      @media screen and (max-width: 1199px) {
        .articles__main-item {
          position: unset;
          width: auto; } }
  .articles__list {
    padding: 60px 30px; }
    @media (max-width: 1024px) {
      .articles__list {
        padding: 0 30px; } }
    .articles__list-anonce {
      color: #370A62; }
