@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.articles_list__title {
  text-align: center;
  border-bottom: 1px solid #6D0A5E;
  padding-bottom: 15px;
  color: #370A62;
  width: 25%;
  margin: 45px auto; }
  @media screen and (max-width: 1199px) {
    .articles_list__title {
      width: 100%; } }

.articles_list__link {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  color: #000000;
  webkit-box-shadow: 0 0 10px 5px rgba(48, 14, 85, 0.3);
  box-shadow: 0 0 10px 5px rgba(48, 14, 85, 0.3); }
  .articles_list__link:hover {
    transition: 1s ease;
    webkit-box-shadow: 0 0 20px 5px rgba(48, 14, 85, 0.55);
    box-shadow: 0 0 20px 5px rgba(48, 14, 85, 0.55); }

.articles_list__img {
  z-index: 500; }
  @media (max-width: 767px) {
    .articles_list__img {
      display: none; } }
  .articles_list__img > img {
    display: block; }

.articles_list__text {
  padding: 30px;
  color: #000000;
  background-color: #fff;
  z-index: 500; }
  @media (max-width: 1199px) {
    .articles_list__text {
      padding: 15px 15px 0 15px; } }
  .articles_list__text-info {
    display: flex;
    justify-content: space-between; }
  .articles_list__text-title {
    color: #370A62;
    margin: 0; }
  .articles_list__text-date {
    color: #575757;
    font-size: 0.8em; }
  .articles_list__text-anonce {
    line-height: 1.3em; }

.articles_list__tags {
  color: #370A62;
  font-weight: 700;
  font-size: 0.9em;
  display: flex;
  justify-content: flex-end;
  margin-top: 60px; }
  @media (max-width: 1199px) {
    .articles_list__tags {
      margin: 0;
      font-size: 0.6em; } }
  @media (max-width: 767px) {
    .articles_list__tags {
      display: none; } }
  .articles_list__tags-item {
    padding: 3px 10px;
    border: 1px solid #6D0A5E;
    border-radius: 50px;
    margin-right: 10px; }
