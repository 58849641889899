@font-face {
  font-family: "Gilroy Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy Regular"), local("Gilroy-Regular"), url(fonts/gilroy-regular.ttf) format("truetype"); }

@font-face {
  font-family: "Gilroy heavy";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy heavy"), local("Gilroy-heavy"), url(fonts/gilroy-heavy.ttf) format("truetype"); }

@font-face {
  font-family: "Gilroy light";
  font-style: normal;
  font-weight: 300;
  src: local("Gilroy light"), local("Gilroy-light"), url(fonts/gilroy-ultralight.ttf) format("truetype"); }

html {
  height: 100%;
  color: #000;
  line-height: 1.15;
  /* Correct the line height in all browsers. */
  -webkit-text-size-adjust: 100%;
  /* Prevent adjustments of font size after orientation changes in iOS.*/ }

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 320px;
  height: 100%;
  font-family: "Gilroy Regular", "Arial", sans-serif;
  font-style: normal;
  font-size: 1em;
  margin: 0;
  /* Remove the margin in all browsers.*/
  color: #FFFFFF; }

h1, h2 {
  font-size: 1.2em;
  font-family: "Gilroy heavy", sans-serif;
  letter-spacing: 1px; }

h3, h4, h5 {
  font-size: 1em;
  font-family: "Gilroy heavy", sans-serif;
  font-weight: 650;
  letter-spacing: 1px; }

hr {
  box-sizing: content-box;
  /* Add the correct box sizing in Firefox*/
  height: 0;
  overflow: visible;
  /* Show the overflow in Edge and IE.*/ }

pre {
  font-family: monospace, monospace;
  /* Correct the inheritance and scaling of font size in all browsers.*/
  font-size: 1em;
  /* Correct the odd `em` font sizing in all browsers.*/ }

a {
  text-decoration: none;
  color: #FFFFFF; }

a:focus {
  outline-offset: unset; }

sub,
sup {
  font-size: 75%;
  /* Prevent `sub` and `sup` elements from affecting the line height in all browsers.*/
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

img {
  border: 0;
  /* Remove the border on images inside links in IE 10.*/ }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* Change the font styles in all browsers.*/
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  /* Remove the margin in Firefox and Safari.*/
  border: 0;
  /* Remove the border in Firefox*/ }

button,
input {
  overflow: visible;
  /* Show the overflow in IE/Edge*/ }

button,
select {
  text-transform: none;
  /* Remove the inheritance of text transform in Edge, Firefox, and IE.*/ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS and Safari.*/ }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border: 0;
  /*Remove the inner border and padding in Firefox.*/
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
  /* Restore the focus styles unset by the previous rule.*/ }

textarea {
  overflow: auto;
  /* Remove the default vertical scrollbar in IE 10+.*/ }

.hidden {
  display: none; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* Add the correct box sizing in IE 10.*/
  padding: 0;
  /* Remove the padding in IE 10.*/ }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
  /* Correct the cursor style of increment and decrement buttons in Chrome.*/ }

[type="search"] {
  -webkit-appearance: textfield;
  /* Correct the odd appearance in Chrome and Safari.*/
  outline-offset: -2px;
  /* Correct the outline style in Safari.*/ }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* Remove the inner padding in Chrome and Safari on macOS.*/ }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* Correct the inability to style clickable types in iOS and Safari.*/
  font: inherit;
  /* Change font properties to `inherit` in Safari.*/ }

.main_background {
  background-color: #F6EEFD;
  position: relative; }
  .main_background::before {
    content: '';
    background: url(images/articles_list-astrologo.png) no-repeat 100% 100%;
    width: 583px;
    height: 587px;
    position: absolute;
    z-index: 50;
    top: -200px;
    right: 0; }
    @media (max-width: 767px) {
      .main_background::before {
        display: none; } }
  .main_background::after {
    content: '';
    background: url(images/articles_list-astrologo-taro.png) no-repeat 100% 100%;
    width: 617px;
    height: 526px;
    position: absolute;
    z-index: 50;
    bottom: -200px;
    left: 0; }
    @media (max-width: 767px) {
      .main_background::after {
        display: none; } }
