@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.menu {
  border-bottom: 1px solid #686351; }
  @media screen and (max-width: 992px) {
    .menu {
      display: none; } }
  .menu__list {
    display: flex;
    justify-content: space-around;
    list-style: none;
    font-size: 1.2em;
    margin: 0;
    padding-left: 0; }
    .menu__list-item {
      z-index: 700;
      position: relative;
      height: 50px;
      flex-grow: 8;
      position: relative; }
      .menu__list-item:hover .menu__list-dropdown {
        display: block; }
    .menu__list-link {
      color: #fff;
      text-decoration: none;
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      overflow: hidden;
      justify-content: center; }
      .menu__list-link::after {
        content: '';
        display: block;
        width: 60%;
        height: 20px;
        position: absolute;
        top: 56px;
        opacity: 0;
        border-radius: 100px 100px;
        transition: all .85s ease; }
      .menu__list-link:hover {
        color: #FDEFA9;
        transition: all .85s ease; }
      .menu__list-link:hover::after {
        opacity: 1;
        webkit-box-shadow: 0 0 1px #960a86, 0 0 3px #960a86, 0 0 5px #960a86, 0 0 10px #960a86, 0 0 15px #ec03d1, 0 0 20px #ec03d1, 0 0 25px #c91cb5, 0 0 30px #c91cb5;
        box-shadow: 0 0 1px #960a86, 0 0 3px #960a86, 0 0 5px #960a86, 0 0 10px #960a86, 0 0 15px #ec03d1, 0 0 20px #ec03d1, 0 0 25px #c91cb5, 0 0 30px #c91cb5; }
      .menu__list-link-icon {
        position: relative; }
      .menu__list-link-icon::before {
        content: '';
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEJSURBVHgBndKtbgJBEMDxWdLWQtqkspyta/oCxdS0SUNfoKkjBMMbgOANUBh4BQwCBQZHgkQgEASB4iMEgeD4TxjCknAEmOR3H7s7sx93LgzDrIjE5Ya4wzPyqF+Rl0ZVmDmOIVL7Hp4T+MU/fhB4fYGND/YN3/i0pAoWaKOGFjb2rIlppXkxvTjnGtzWGGCFF5SRQAVJjNDBA+MPW6TSo1XPeG06u8ZMV2RtX9b2dnQCNBT8vXDv28ChvSfR023JqbACY+T0APGHVxQxRUnOhR1KDStMsLRD+5BLwgrc21Kf/E/lRywiXwePMUcXJ2d1Ej17U3Z/35xPk5JrwpY8jVryJQXez/VvASSnAPJVP7KEAAAAAElFTkSuQmCC) no-repeat 50%/contain;
        width: 15px;
        height: 14px;
        position: absolute;
        right: 32px;
        top: 20px; }
        @media (max-width: 1199px) {
          .menu__list-link-icon::before {
            right: 0; } }
    .menu__list-dropdown {
      list-style: none;
      display: none;
      position: absolute;
      z-index: 600;
      width: 100%;
      margin: 0 auto; }
      .menu__list-dropdown-item {
        background: rgba(48, 14, 85, 0.85);
        width: 75%;
        transition: 3s; }
