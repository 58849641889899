@charset "UTF-8";
/**
 * Подчеркивания ссылок через border.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $underline - отображать подчеркивание по умолчанию.
 */
/*
 * Подчеркивания ссылок через box-shadow.
 * Подходит для использования, если надо регулировать расположение подчеркивания относительно текста.
 *
 * $color - цвет текста.
 * $border-color - цвет подчеркивания.
 * $border-alpha - значение прозрачности для rgba.
 * $background-color - цвет фона на кототром расположена ссылка.
 * $y1 - расположение тени фона.
 * $y2 - расположение тени подчеркивания.
 */
/* stylelint-disable order/order  */
/**
 * Конвертирует размер шрифта из PX в REM.
 *
 * $pixels - значение шрифта в пикселях.
 * $context - значения контекста шрифта (если не задано, то берем из переменной базовое значение).
 */
.header__burger {
  z-index: 1000;
  position: relative;
  display: none; }
  @media screen and (max-width: 992px) {
    .header__burger {
      display: block; } }

.burger-menu {
  position: absolute;
  right: 0;
  z-index: 999; }

.burger {
  width: 1.5rem;
  height: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .burger span {
    transition: all 0.25s;
    transform: rotate(0);
    display: block;
    height: 2px;
    transform-origin: 7%; }
  [aria-expanded="true"] .burger span:first-child {
    transform: rotate(45deg); }
  [aria-expanded="true"] .burger span:nth-child(2) {
    opacity: 0; }
  [aria-expanded="true"] .burger span:last-child {
    transform: rotate(-45deg); }

.burger-white {
  background: #fff;
  height: 2px; }
